import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  About,
  Contacts,
  Home,
  Login,
  NavBar,
  Register,
  AdminLogin,
  Forgot,
  PrivacyPolicy,
  ResetPassword,
  Terms,
  Whatsapp,
  NotFound,
  CreateVisaForm,
  RefundPolicy,
  ApplicationGuide,
  FinalSubmit,
  Payment,
  LearnMore,
  HowItworks,
} from "./compononents/components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashBoard from "./admin/dashboard/DashBoard";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getLoginStatus } from "./services/authService";
import { SET_LOGIN } from "./redux/features/auth/authSlice";

axios.defaults.withCredentials = true;
const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-QPD17HQ7QX";
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-QPD17HQ7QX');
    `;
    document.head.appendChild(script2);
  }, []);

  useEffect(() => {
    const checkLoginStatus = async () => {
      const status = await getLoginStatus();
      dispatch(SET_LOGIN(status));
    };
    checkLoginStatus();
  }, [dispatch]);
  return (
    <Router>
      <NavBar />
      <Whatsapp />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/sign-up" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/admin/kenvisas/login" element={<AdminLogin />} />
        <Route path="/admin-kenvisas-admin-dashboard" element={<DashBoard />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/eta-application" element={<CreateVisaForm />} />
        <Route path="/forgot-password" element={<Forgot />} />
        <Route path="/submit-application" element={<FinalSubmit />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/application-guide" element={<ApplicationGuide />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/policies" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/learn-more" element={<LearnMore />} />
        <Route path="/how-it-works" element={<HowItworks />} />
        <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
};
export default App;
