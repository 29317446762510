import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Loader from "../../compononents/Loader";
import { toast } from "react-hot-toast";
import { server } from "../../server";

const VisaTable = () => {
  const [visaData, setVisaData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${server}/visas`)
      .then((response) => {
        setVisaData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("Error fetching data. Please login", error);
        setIsLoading(false);
      });
  }, []);

  const deleteUserById = (id) => {
    axios
      .delete(`${server}/visas/${id}`)
      .then(() => {
        const updatedVisaData = visaData.filter((visa) => visa.id !== id);
        setVisaData(updatedVisaData);
        toast.success("User deleted successfully");
      })
      .catch((error) => {
        toast.error("Error deleting user:", error);
      });
  };

  const downloadImage = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.target = "_blank"; // Open the image in a new tab
    link.click();
  };

  return (
    <div className="container mx-auto mt-5">
      {isLoading && <Loader />}
      <p className="text-2xl text-green-500">Total Visa: {visaData.length}</p>
      <h1 className="text-2xl font-bold mb-4">ETA Data</h1>
      <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-2 px-4">Telephone</th>
            <th className="py-2 px-4">Email</th>
            <th className="py-2 px-4">Date of Arrival</th>
            <th className="py-2 px-4">Passport Photo</th>
            <th className="py-2 px-4">Bio Data Page</th>
            <th className="py-2 px-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          {visaData.map((visa, index) => (
            <tr key={index}>
              <td className="py-2 px-2">{visa?.telephone}</td>
              <td className="py-2 px-2">{visa?.email}</td>
              <td className="py-2 px-2">{visa?.dateOfArrival}</td>
              <td className="py-2 px-4">
                <button
                  onClick={() => downloadImage(visa?.passportPhoto)}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                  Download Passport Photo
                </button>
              </td>
              <td className="py-2 px-4">
                <button
                  onClick={() => downloadImage(visa?.bioDataPage)}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                  Download Bio Data Page
                </button>
              </td>
              <td className="py-2 px-4">
                <DeleteIcon
                  className="hover:text-red-500 cursor-pointer"
                  onClick={() => deleteUserById(visa._id)}
                />
                Delete
              </td>
            </tr>
          ))}

          {visaData.length === 0 && (
            <div className="text-3xl bg-red-500 flex justify-center mt-4">
              No New Data!!
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default VisaTable;
