import { createSlice } from '@reduxjs/toolkit'

const initialState = {
visa:null,
visas:[],
isError:false,
isSuccess:false,
isLoading:false,
message:""
}

const visaSlice = createSlice({
  name: "visa",
  initialState,
  reducers: {
    CAL_STORE_VALUE(state, action){
        console.log("Store value");
    }
  },
  extraReducers:(builder)=>{

  }
});

export const {CAL_STORE_VALUE} = visaSlice.actions

export default visaSlice.reducer