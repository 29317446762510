import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { format, startOfToday } from "date-fns";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import { server } from "../server";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { FiUpload } from "react-icons/fi";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { FlagIcon } from "react-flag-kit";
import Select from "react-select";
import countryList from "react-select-country-list";
import Payment from "./Payment";

const showToast = (message, isError = false) => {
  toast(message, {
    duration: 5000,
    style: {
      background: isError ? "#FF6F61" : "#4BB543",
      color: "white",
    },
    position: "top-center",
  });
};

const VisaApplicationForm = () => {
  const navigate = useNavigate();
  useRedirectLoggedOutUser("/");

  const [isLoading, setIsLoading] = useState(false);
  const [flagCode, setFlagCode] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    telephone: "",
    dateOfArrival: "",
    passportPhoto: null,
    bioDataPage: null,
    country: "",
  });
  const [imagePreviews, setImagePreviews] = useState({
    passportPhoto: null,
    bioDataPage: null,
  });
  const [paymentStep, setPaymentStep] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0);

  const restrictedCountries = [
    "Somalia",
    "Libya",
    "Kosovo",
    "North Korea",
    "Syria",
    "Tajikistan",
    "Palestine",
    "Lebanon",
    "Yemen",
    "Afghanistan",
    "Iraq",
    "Armenia",
    "Azerbaijan",
  ];

  useEffect(() => {
    if (paymentCompleted) {
      handleSubmit();
    }
  }, [paymentCompleted]);

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;

    if (type === "file") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: newValue,
      }));
      setImagePreviews({
        ...imagePreviews,
        [name]: URL.createObjectURL(files[0]),
      });
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    }
  };

  const handlePhoneInputChange = (value, country) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      telephone: value,
    }));

    if (country) {
      setFlagCode(country.iso2);
    }
  };

  const handleCountryChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      country: selectedOption.label,
    }));

    if (restrictedCountries.includes(selectedOption.label)) {
      setPaymentAmount(151);
    } else {
      setPaymentAmount(100);
    }
  };

  const validateForm = () => {
    const { email, telephone, passportPhoto, bioDataPage, country } = formData;
    if (!email || !telephone || !passportPhoto || !bioDataPage || !country) {
      if (!email) showToast("Email is required", true);
      if (!telephone) showToast("Phone number is required", true);
      if (!passportPhoto) showToast("Passport photo is required", true);
      if (!bioDataPage) showToast("Bio data page is required", true);
      if (!country) showToast("Country selection is required", true);
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      showToast("Please enter a valid email address", true);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (key === "passportPhoto" || key === "bioDataPage") {
          if (formData[key]) {
            formDataToSend.append(key, formData[key]);
          }
        } else {
          formDataToSend.append(key, formData[key]);
        }
      }
      setIsLoading(true);
      await axios.post(`${server}/visas/create-eta`, formDataToSend);

      showToast("Your ETA data has been submitted successfully", false);
      sessionStorage.removeItem("formData");
      navigate("/");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      showToast(errorMessage, true);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentSuccess = () => {
    setPaymentCompleted(true);
    showToast("Payment successful. Submitting your application...", false);
  };

  const options = countryList().getData();

  return (
    <div className="container mx-auto p-4 font-poppins">
      <h2 className="text-2xl font-semibold mb-4 text-center p-4 bg-green-500">
        Submit Your Data
      </h2>
      <h3 className="font-semibold mb-4 text-center p-4 border-4 border-dotted border-green-500 my-4">
        Your ETA will be processed within 30 minutes.
      </h3>
      <p className="text-center p-4 border-4 border-dotted border-green-500 my-4">
        Upon successful payment, your data will be automatically submitted.
      </p>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full py-2 px-3 text-gray-700 border rounded"
            aria-label="Email"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Phone Number <span className="text-red-500">*</span>
          </label>
          <PhoneInput
            country={"us"}
            value={formData.telephone}
            onChange={handlePhoneInputChange}
            inputClass="w-full py-2 px-3 text-gray-700 border rounded block"
            aria-label="Phone Number"
            required
          />
          {flagCode && (
            <FlagIcon code={flagCode} size={32} className="mt-4 block" />
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Country <span className="text-red-500">*</span>
          </label>
          <Select
            options={options}
            value={options.find((option) => option.label === formData.country)}
            onChange={handleCountryChange}
            className="w-full py-2 px-3 text-gray-700 border rounded"
            aria-label="Country"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Date of Arrival <span className="text-red-500">(optional)</span>
          </label>
          <input
            type="date"
            name="dateOfArrival"
            value={formData.dateOfArrival}
            min={format(startOfToday(), "yyyy-MM-dd")}
            onChange={handleInputChange}
            className="w-full py-2 px-3 text-gray-700 border rounded"
            aria-label="Date of Arrival"
          />
        </div>
        <hr className="my-2" />

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Passport Photo <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            name="passportPhoto"
            accept="image/*"
            onChange={handleInputChange}
            className="hidden"
            id="passportPhoto"
            aria-label="Passport Photo"
            required
          />
          <label
            htmlFor="passportPhoto"
            className="flex items-center cursor-pointer"
          >
            <FiUpload className="text-2xl text-green-500 mr-2" />
            <span className="text-green-500">Upload Passport Photo</span>
          </label>
          {imagePreviews.passportPhoto && (
            <img
              src={imagePreviews.passportPhoto}
              alt="Passport PhotoPreview"
              className="mt-2 max-w-xs"
            />
          )}
        </div>
        <hr className="my-2" />

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Bio Data Page <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            name="bioDataPage"
            accept="image/*"
            onChange={handleInputChange}
            className="hidden"
            id="bioDataPage"
            aria-label="Bio Data Page"
            required
          />
          <label
            htmlFor="bioDataPage"
            className="flex items-center cursor-pointer "
          >
            <FiUpload className="text-xl text-green-500 mr-2 " />
            <span className="text-green-500">Upload Bio Data Page</span>
          </label>
          {imagePreviews.bioDataPage && (
            <img
              src={imagePreviews.bioDataPage}
              alt="Bio Data Page Preview"
              className="mt-2 max-w-xs"
            />
          )}
        </div>
        <hr className="my-2" />

        {!paymentStep ? (
          <div className="text-center mt-4">
            <button
              type="button"
              onClick={() => {
                if (validateForm()) {
                  setPaymentStep(true);
                }
              }}
              className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
            >
              Submit
            </button>
          </div>
        ) : (
          <Payment onSuccess={handlePaymentSuccess} amount={paymentAmount} />
        )}
      </form>
      {isLoading && <Loader />}
    </div>
  );
};

export default VisaApplicationForm;
