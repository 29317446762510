import Header from "./Header";
import VisaTable from "./VisaTable";

const DashBoard = () => {
  return (
    <div className="flex h-screen">
      <div className="md:flex-1 flex-col">
        <Header />
        <VisaTable />
      </div>
    </div>
  );
};

export default DashBoard;
