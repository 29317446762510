import Footer from "./Footer";
const About = () => {
  return (
    <>
      <div className="container mx-auto px-4 py-8 font-poppins">
        <div className="container mx-auto">
          <p>
            At EtaKenya, we are a leading immigration consultancy firm dedicated
            to providing personalized immigration solutions to individuals and
            businesses. With our vast expertise and commitment to excellence, we
            strive to be your trusted partner throughout your immigration
            journey.
          </p>

          <h2 className="text-lg font-bold mb-4 ">Our Mission:</h2>
          <p>
            Our mission is to simplify and streamline the immigration process,
            helping you navigate the complexities and challenges with ease. We
            understand that immigration can be a life-changing decision, and we
            are here to support you every step of the way. Our goal is to ensure
            that your immigration experience is smooth, efficient, and
            successful.
          </p>

          <h2 className="text-lg font-bold mt-8 mb-4">Our Services:</h2>
          <ul className="list-disc list-inside ">
            <li>
              Visa Consultation: Our team of experienced immigration consultants
              provides in-depth visa consultations, helping you understand the
              different visa options available to you based on your unique
              circumstances and goals.
            </li>
            <li>
              Documentation Assistance: We understand that gathering the
              necessary documents for your immigration application can be
              overwhelming. Our experts will guide you through the documentation
              process, ensuring that all your paperwork is accurate, complete,
              and submitted on time.
            </li>
            <li>
              Application Preparation: Our team will meticulously prepare and
              review your immigration application to maximize your chances of
              success. We will ensure that your application adheres to all the
              relevant regulations and requirements, leaving no room for errors
              or omissions.
            </li>
            <li>
              Immigration Interviews: If your immigration process involves
              interviews or meetings with immigration officials, we will provide
              you with comprehensive guidance and preparation, ensuring that you
              feel confident and well-prepared.
            </li>
            <li>
              Business Immigration: For businesses seeking to expand their
              global footprint, we offer specialized services tailored to meet
              your corporate immigration needs. We can assist with work permits,
              business visas, intra-company transfers, and more.
            </li>
          </ul>

          <h2 className="text-lg font-bold mt-8 mb-4">Why Choose EtaKenya?</h2>
          <ul className="list-disc list-inside ">
            <li>
              Expertise: Our team of immigration experts has extensive knowledge
              and experience in navigating the complex immigration landscape. We
              stay up-to-date with the latest immigration policies, regulations,
              and changes to provide you with accurate and reliable advice.
            </li>
            <li>
              Personalized Approach: We understand that every individual and
              situation is unique. That's why we take a personalized approach to
              your immigration journey, tailoring our services to meet your
              specific requirements and goals.
            </li>
            <li>
              Timely and Efficient Service: We value your time and strive to
              provide efficient and timely services. Our streamlined processes
              and attention to detail ensure that your immigration applications
              are submitted promptly, avoiding unnecessary delays.
            </li>
            <li>
              Client Satisfaction: Client satisfaction is at the heart of
              everything we do. We measure our success by the success and
              satisfaction of our clients. We are committed to going above and
              beyond to meet your expectations and exceed them.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
