import { createSlice } from "@reduxjs/toolkit";

// Retrieve username from local storage
const storedUsername = localStorage.getItem("username");
const initialState = {
  isLoggedIn: false,
  username: storedUsername ? JSON.parse(storedUsername) : "",
  user: {
    email: "",
    bioDataPage: "",
    passportPhoto: "",
    dateOfArrival: "",
    telephone: "",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_LOGIN(state, action) {
      state.isLoggedIn = action.payload;
    },
    SET_USERNAME(state, action) {
      localStorage.setItem("username", JSON.stringify(action.payload));
      state.username = action.payload;
    },
    SET_USER(state, action) {
      const profile = action.payload;
      state.user.email = profile.email;
      state.user.bioDataPage = profile.bioDataPage;
      state.user.passportPhoto = profile.passportPhoto;
      state.user.dateOfArrival = profile.dateOfArrival;
      state.user.telephone = profile.telephone;
      // Corrected typo in property name
    },
  },
});

export const { SET_LOGIN, SET_USERNAME, SET_USER } = authSlice.actions;
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectUserName = (state) => state.auth.username; // Corrected property name
export const selectUser = (state) => state.auth.user;

export default authSlice.reducer;
