import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoginIcon from "@mui/icons-material/Login";
import Loader from "../../compononents/Loader";
import {
  SET_ADMIN_LOGIN,
  SET_ADMIN_USERNAME,
} from "../../redux/features/authSliceAdmin";
import axios from "axios";
import { server } from "../../server";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    adminUsername: "",
    adminUserId: "",
    adminPassword: "",
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const { adminUsername, adminPassword, adminUserId } = formData;
    if (!adminUsername) {
      setError(true);
      return toast.error("Please enter a username");
    }
    if (!adminPassword) {
      setError(true);
      return toast.error("Please enter admin password");
    }
    if (!adminUserId) {
      setError(true);
      return toast.error("Please enter admin User Id");
    }
    try {
      setError(null);
      setIsLoading(true);
      const response = await axios.post(
        `${server}/users/admin/kenvisas/login`,
        formData
      );

      const { adminUsername } = response.data;

      await dispatch(SET_ADMIN_LOGIN(true));
      setIsLoading(false);
      await dispatch(SET_ADMIN_USERNAME(adminUsername));
      navigate("/admin-kenvisas-admin-dashboard");
      toast.success(`Welcome back admin ${adminUsername}🚀🚀`);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      setIsLoading(false);
    }
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 font-poppins">
      {isLoading && <Loader />}
      <div className="bg-white p-8 rounded shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold text-center mb-4">
          Admin Login <LoginIcon />
        </h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label htmlFor="email" className="block font-medium mb-1">
              Admin Username
              <span className="text-red-700 text-[15px] ml-1 ">*</span>
            </label>
            <input
              type="text"
              name="adminUsername"
              value={formData.adminUsername}
              onChange={handleInputChange}
              placeholder="Enter admin username:"
              className={`p-2 mb-4  font-medium w-full rounded-md  focus:outline-none  border-[1px] border-gray-400 px-3 py-2 ${
                error ? "border-red-500" : "border-green-500"
              } `}
            />
            {error && formData.adminUsername.length <= 0 ? (
              <p className="text-red-700 text-[14px]">
                Admin Username cannot be empty! or incorrect
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block font-medium mb-1">
              Admin User Id
              <span className="text-red-700 text-[15px] ml-1 ">*</span>
            </label>
            <input
              type="number"
              name="adminUserId"
              value={formData.adminUserId}
              onChange={handleInputChange}
              placeholder="Enter admin User Id:"
              className={`p-2 mb-4  font-medium w-full rounded-md  focus:outline-none  border-[1px] border-gray-400 px-3 py-2 ${
                error ? "border-red-500" : "border-green-500"
              } `}
            />
            {error && formData.adminUsername.length <= 0 ? (
              <p className="text-red-700 text-[14px]">
                Please enter your admin User Id
              </p>
            ) : (
              ""
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="password" className="block font-medium mb-1">
              Password<span className="text-red-700 text-[15px] ml-1 ">*</span>
            </label>
            <input
              type="password"
              name="adminPassword"
              placeholder="Enter admin password"
              value={formData.adminPassword}
              onChange={handleInputChange}
              className={`p-2 mb-4  font-medium w-full rounded-md  focus:outline-none  border-[1px] border-gray-400 px-3 py-2 ${
                error ? "border-red-500" : "border-green-500"
              } `}
            />
            {error && formData.adminPassword.length < 6 ? (
              <p className="text-red-700 text-[14px]">
                Password is incorrect!!
              </p>
            ) : (
              ""
            )}
          </div>

          <button
            type="submit"
            className="w-full bg-green-500 text-white rounded-md px-4 py-2 font-medium hover:bg-green-600"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
