import { useNavigate } from "react-router-dom";
import { logoutAdmin } from "../../services/adminAuthServices";
import { useDispatch } from "react-redux";
import { SET_ADMIN_LOGIN } from "../../redux/features/authSliceAdmin";
import { useEffect, useState } from "react";

const Header = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminUsername = localStorage.getItem("adminUsername");
  const username = JSON.parse(adminUsername);

  const logout = async () => {
    await logoutAdmin();
    await dispatch(SET_ADMIN_LOGIN(false));
    navigate("/admin/kenvisas/login");
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, []);

  return (
    <header className="flex justify-around items-center p-4 bg-gray-800 text-white">
      <div className="text-2xl">ETAKENYA ADMIN DASHBOARD</div>
      <div className="flex items-center">
        <input
          type="text"
          placeholder="Search"
          className="ml-4 p-2 rounded-md bg-gray-700 text-white"
        />
      </div>
      <div>
        <h1>Current Time:</h1>
        <p className="text-2xl">{currentTime.toLocaleTimeString()}</p>
      </div>
      <div className="flex items-center">
        <div className="relative group">
          <button className="text-green-500 p-4 text-[20px]">
            Welcome {username} 😍
          </button>
          <ul className="absolute hidden group-hover:block bg-white text-black p-2 shadow w-[100%]">
            <li>View Profile</li>
            <button
              onClick={logout}
              className="bg-red-500 text-white px-4 py-2 rounded-lg ml-4 hover:bg-red-600"
            >
              Logout
            </button>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
