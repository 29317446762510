import { useState } from "react";
import { toast } from "react-hot-toast";
import Loader from "../Loader";
import { forgotPassword, validateEmail } from "../../services/authService";
import LoginIcon from "@mui/icons-material/Login";
const Forgot = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const forgot = async (e) => {
    e.preventDefault();
    if (!email) {
      setError(true);
      return toast.error("Please enter an email");
    }

    if (!validateEmail(email)) {
      setError(true);
      return toast.error("Please enter a valid email");
    }

    const userData = {
      email,
    };
    setIsLoading(true);
    await forgotPassword(userData);
    setEmail("");
    setIsLoading(false);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 font-poppins">
      {isLoading && <Loader />}
      <div className="bg-white p-8 rounded shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold text-center mb-4">
          Reset Password <LoginIcon />
        </h2>
        <p className="  m-4">Please enter your email to reset your password</p>
        <form onSubmit={forgot}>
          <div className="mb-4">
            <label htmlFor="email" className="block font-medium mb-1">
              Email<span className="text-red-700 text-[15px] ml-1 ">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`p-2 mb-4  font-medium w-full rounded-md  focus:outline-none  border-[1px] border-gray-400 px-3 py-2 ${
                error ? "border-red-500" : "border-green-500"
              } `}
              placeholder="Enter your email"
            />
            {error && email.length <= 0 ? (
              <p className="text-red-700 text-[14px]">Please fill in email</p>
            ) : (
              ""
            )}
          </div>

          <button
            type="submit"
            className="w-full bg-green-500 text-white rounded-md px-4 py-2 font-medium hover:bg-green-600"
          >
            Reset
          </button>
          <div className="flex justify-around text-[14px] mt-2">
            <h3>
              Not forgotten? <br />
              <a href="/login ">
                <p className=" text-green-500 hover:text-green-600">
                  Login Here
                </p>
              </a>
            </h3>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Forgot;
