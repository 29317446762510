import { useState } from "react";
import { FaInfoCircle, FaChevronUp, FaChevronDown } from "react-icons/fa";
import Footer from "./Footer";

const steps = {
  "Step 1: Prepare Your Documents":
    "Before you start the ETA application process, make sure you have all the necessary documents ready. This typically includes a valid passport, a recent passport-sized photograph, and travel itinerary details. Having these documents on hand will make the process smoother and quicker.",

  "Step 2: Complete the Application Form":
    "Create your account with us  and fill out the online ETA application form. Provide accurate information as per the requirements. Double-check all entries to avoid errors that could delay your ETA approval.",

  "Step 3: Submit and Pay":
    "After completing the form, submit your application and proceed to the payment section. EtaKenya offers a secure payment gateway where you can pay the ETA processing fee using various payment methods. Once payment is confirmed, your application will be processed by the relevant authorities.",

  "Step 4: Receive Your ETA":
    "After your application is processed, you will receive your ETA via email. Print a copy of your ETA and keep it with your travel documents. Present it to immigration authorities upon arrival in Kenya. With your ETA in hand, you're all set to enjoy your trip to Kenya!",

  "Step 5: Start Your Journey":
    "With your approved ETA, you are ready to embark on your journey to Kenya. Make sure all your travel documents are in order, and you have your ETA printed out and easily accessible. Enjoy your trip and the unique experiences Kenya has to offer!",
};

const HowItWorks = () => {
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  return (
    <>
      <section className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8">
            <FaInfoCircle className="text-green-500 text-4xl mb-2 mx-auto" />
            <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              How It Works
            </h2>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto">
              Follow these simple steps to obtain your ETA and start your
              journey to Kenya.
            </p>
          </div>

          <ul className="space-y-4">
            {Object.keys(steps).map((item, index) => (
              <li
                key={index}
                className="relative group p-4 border rounded cursor-pointer hover:bg-green-500 duration-300"
                onClick={() => toggleItem(index)}
              >
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-medium text-gray-900 group-hover:text-white">
                    {item}
                  </h3>
                  {openItem === index ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                {openItem === index && (
                  <div className="mt-2 p-2 bg-gray-100 rounded transition-opacity duration-300 ease-in-out">
                    <p className="text-gray-700">{steps[item]}</p>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default HowItWorks;
