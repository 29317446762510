import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  FaRegStar,
  FaRegClock,
  FaLock,
  FaHeadset,
  FaRegCheckCircle,
  FaLaptopHouse,
} from "react-icons/fa";
import Image1 from "../assets/etaImage.jpg";

import Image3 from "../assets/Lady_Holding_Passport.png";
import Footer from "./Footer";

const Hero = () => {
  const navigate = useNavigate();
  const ShowLogin = useSelector((state) => state.auth.isLoggedIn);

  const showToast = (message, isError = false) => {
    toast(message, {
      duration: 5000,
      style: {
        background: isError ? "#FF6F61" : "#4BB543",
        color: "white",
      },
      position: "top-center",
    });
  };
  const onClickLearnmore = (e) => {
    e.preventDefault();
    navigate("/learn-more");
  };
  const handleHowItworks = (e) => {
    e.preventDefault();
    navigate("/how-it-works");
  };

  const handleClick = (e) => {
    e.preventDefault();
    try {
      if (ShowLogin) {
        navigate("/application-guide");
        showToast("You are now redirected to the ETA application page", false);
      } else {
        showToast(
          "Please log in to apply for your ETA, you are redirected to login",
          true
        );
        navigate("/login");
      }
    } catch (error) {
      showToast("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <div className="min-h-screen bg-green-50 text-gray-900 dark:bg-gray-900 dark:text-white flex flex-col justify-between">
        {/* Hero Section */}
        <div className="bg-green-200 flex flex-col lg:flex-row items-center justify-between px-8 py-16">
          <div className="max-w-lg mb-8 lg:mb-0">
            <h1 className="text-4xl font-bold text-black mb-4">
              APPLY FOR YOUR ETA TO TRAVEL TO KENYA
            </h1>
            <p className="text-lg text-black mb-4">
              We ensure that you have your Electronic Travel Authorization (ETA)
              to visit Kenya quickly and easily.
            </p>
            <p className="text-lg text-black mb-8">
              We ensure a hassle-free travel experience by securing your ETA
              online before your trip, ensuring a smooth entry into the country.
            </p>
            <div className="flex flex-col sm:flex-row sm:space-x-4 justify-center">
              <form onSubmit={handleClick}>
                <div className="m-2 sm:m-0">
                  <button
                    type="submit"
                    className="bg-black text-white px-6 py-3 rounded-lg hover:bg-gray-800"
                  >
                    Apply Now
                  </button>
                </div>
              </form>
              <div className="mt-4 sm:mt-0">
                <button
                  onClick={handleHowItworks}
                  className="bg-black text-white px-6 py-3 rounded-lg hover:bg-gray-800"
                >
                  How it Works
                </button>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <img
              src={Image3}
              alt="Travel"
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>

        {/* What is an ETA Section */}
        <div className="bg-green-200 flex flex-col lg:flex-row items-center justify-between px-8 py-16">
          <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
            <img
              src={Image1}
              alt="ETA Document"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
          <div className="max-w-lg">
            <h2 className="text-3xl font-bold text-black mb-4">
              What is an ETA?
            </h2>
            <h3 className="text-xl font-semibold text-black mb-4">
              Electronic Travel Authorization (ETA)
            </h3>
            <p className="text-lg text-black mb-4">
              An Electronic Travel Authorization (ETA) is a digital travel
              permit required by some countries, including Kenya, for foreign
              visitors before they enter the country.
            </p>
            <p className="text-lg text-black mb-8">
              The ETA simplifies the visa application process by allowing
              travelers to apply online, eliminating the need for a physical
              visa application at an embassy.
            </p>
            <button
              onClick={onClickLearnmore}
              className="bg-black text-white px-6 py-3 rounded-lg hover:bg-gray-800"
            >
              Learn More
            </button>
          </div>
        </div>

        {/* How to Apply Section */}

        {/* Why Apply Via Us Section */}
        <div className="bg-green-200 px-8 py-16">
          <h2 className="text-3xl font-bold text-black text-center mb-4">
            Why Apply Via Us
          </h2>
          <p className="text-lg text-black text-center mb-12">
            We offer expert guidance throughout the application process,
            ensuring all your details are correctly submitted to avoid delays.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="text-center p-4 bg-white rounded-lg shadow-lg">
              <FaRegStar className="text-4xl text-green-600 mb-4 mx-auto" />
              <h3 className="text-xl font-bold text-black mb-2">
                Expert Assistance
              </h3>
              <p className="text-black">
                Our team guides you through each step, ensuring accurate
                information submission, reducing the risk of errors, and
                streamlining the approval process for your travel authorization.
              </p>
            </div>
            <div className="text-center p-4 bg-green-400 rounded-lg shadow-lg">
              <FaRegClock className="text-4xl text-white mb-4 mx-auto" />
              <h3 className="text-xl font-bold text-white mb-2">
                Fast Processing
              </h3>
              <p className="text-white">
                We prioritize your application to ensure quick processing times,
                helping you get your ETA approved swiftly so you can focus on
                planning your trip without unnecessary delays.
              </p>
            </div>
            <div className="text-center p-4 bg-white rounded-lg shadow-lg">
              <FaLock className="text-4xl text-green-600 mb-4 mx-auto" />
              <h3 className="text-xl font-bold text-black mb-2">
                Secure Transactions
              </h3>
              <p className="text-black">
                We use top-notch encryption and secure payment gateways to
                protect your personal and financial information, giving you
                peace of mind during the application process.
              </p>
            </div>
            <div className="text-center p-4 bg-green-400 rounded-lg shadow-lg">
              <FaHeadset className="text-4xl text-white mb-4 mx-auto" />
              <h3 className="text-xl font-bold text-white mb-2">
                24/7 Support
              </h3>
              <p className="text-white">
                Our customer support team is available around the clock to
                answer any questions or concerns you may have, providing you
                with timely assistance whenever you need it.
              </p>
            </div>
            <div className="text-center p-4 bg-white rounded-lg shadow-lg">
              <FaRegCheckCircle className="text-4xl text-green-600 mb-4 mx-auto" />
              <h3 className="text-xl font-bold text-black mb-2">
                Error-Free Applications
              </h3>
              <p className="text-black">
                With our careful review of your application, we minimize the
                chances of rejection due to common errors, ensuring a smooth and
                successful submission process.
              </p>
            </div>
            <div className="text-center p-4 bg-green-400 rounded-lg shadow-lg">
              <FaLaptopHouse className="text-4xl text-white mb-4 mx-auto" />
              <h3 className="text-xl font-bold text-white mb-2">
                Convenient Process
              </h3>
              <p className="text-white">
                Our user-friendly platform makes the application process simple
                and accessible, allowing you to apply for your ETA from the
                comfort of your home, anytime.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Hero;
