import { useParams } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Loader from "../Loader";
import { resetPassword } from "../../services/authService";
import LoginIcon from "@mui/icons-material/Login";

const initialState = {
  password: "",
  password2: "",
};

const Reset = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const { password, password2 } = formData;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { resetToken } = useParams();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const reset = async (e) => {
    e.preventDefault();

    if (password.length < 6) {
      setError(true);
      return toast.error("Passwords must be at least 6 characters long");
    }
    if (password !== password2) {
      setError(true);
      return toast.error("Passwords do not match");
    }

    const userData = {
      password,
      password2,
    };
    setIsLoading(true);
    try {
      const data = await resetPassword(userData, resetToken);
      navigate("/login");
      toast.success(data.message);
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 font-poppins">
      <div className="bg-white p-8 rounded shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold text-center mb-4">
          Reset Password <LoginIcon />
        </h2>
        <form onSubmit={reset}>
          <div className="mb-4">
            <label htmlFor="password" className="block font-medium mb-1">
              Password<span className="text-red-700 text-[15px] ml-1 ">*</span>
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={handleInputChange}
              className={`p-2 mb-4 font-medium w-full rounded-md focus:outline-none border-[1px] border-gray-400 px-3 py-2 ${
                error && password.length < 6
                  ? "border-red-500"
                  : "border-green-500"
              }`}
              placeholder="Enter your password"
            />
            {error && password.length < 6 && (
              <p className="text-red-700 text-[14px]">
                Password must be at least 6 characters long
              </p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="password2" className="block font-medium mb-1">
              Confirm Password
              <span className="text-red-700 text-[15px] ml-1 ">*</span>
            </label>
            <input
              type="password"
              id="password2"
              name="password2"
              value={password2}
              onChange={handleInputChange}
              className={`p-2 mb-4 font-medium w-full rounded-md focus:outline-none border-[1px] border-gray-400 px-3 py-2 ${
                error && password !== password2
                  ? "border-red-500"
                  : "border-green-500"
              }`}
              placeholder="Confirm your password"
            />
            {error && password !== password2 && (
              <p className="text-red-700 text-[14px]">Passwords do not match</p>
            )}
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <button
              type="submit"
              className="w-full bg-green-500 text-white rounded-md px-4 py-2 font-medium hover:bg-green-600"
            >
              Reset
            </button>
          )}

          <div className="flex justify-around text-[14px] mt-2">
            <h3>
              Not Forgotten your password? <br />
              <a href="/">
                <p className="text-red-500 hover:text-red-600">Go to Home</p>
              </a>
            </h3>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Reset;
