import { toast } from "react-hot-toast";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import styles from "../../style";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import axios from "axios";
import { server } from "../../server";
import Loader from "../Loader";
const Register = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [visible, setVisible] = useState(false);

  const { username, email, password } = formData;

  const showToast = (message, isError = false) => {
    toast(message, {
      duration: 5000,
      style: {
        background: isError ? "#FF6F61" : "#4BB543",
        color: "white",
      },
      position: "top-center",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    if (!username || !email || !password) {
      showToast("All fields are required.", true);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const response = await axios.post(`${server}/users/register`, formData);
      showToast(
        "User registered successfully🚀🚀, You are now redirected to login page!!",
        response.data.message
      );
      navigate("/login");
      setFormData({
        username: "",
        email: "",
        password: "",
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showToast(error.response.data.message, true);
    }
  };
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
          Register
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Full Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="username"
                  autoComplete="name"
                  value={username}
                  onChange={handleInputChange}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-coralPink focus:border-pink sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={handleInputChange}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-coralPink focus:border-pink sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={handleInputChange}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-coralPink focus:border-pink sm:text-sm"
                />
                <span
                  className="absolute right-2 top-2 cursor-pointer"
                  onClick={() => setVisible((prevState) => !prevState)}
                >
                  {visible ? (
                    <AiOutlineEye size={25} />
                  ) : (
                    <AiOutlineEyeInvisible size={25} />
                  )}
                </span>
              </div>
            </div>

            <div className="flex justify-center">
              {isLoading ? (
                <Loader />
              ) : (
                <button
                  type="submit"
                  className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-300 hover:bg-green-500"
                >
                  Sign Up
                </button>
              )}
            </div>
            <div className={`${styles.noramlFlex} w-full flex justify-between`}>
              <h4>Already have an account?</h4>
              <Link to="/login" className="text-green-500 pl-2">
                Sign In
              </Link>
              <Link to="/" className="text-green-500 pl-2">
                Go to home
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
