import axios from "axios";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";
import { useEffect, useState } from "react";
import { server } from "../server";
import toast from "react-hot-toast";

const showToast = (message, isError = false) => {
  toast(message, {
    duration: 5000,
    style: {
      background: isError ? "#FF6F61" : "#4BB543",
      color: "white",
    },
    position: "top-center",
  });
};

const Payment = ({ onSuccess, amount }) => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    axios
      .get(`${server}/users/getuser`)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        toast.error(error.message || "Failed to fetch user data");
      });
  }, []);

  const email = userData?.email;
  const phone_number = userData?.telephone;
  const name = localStorage.getItem("username");

  const config = {
    public_key: "FLWPUBK-04a71d15cd080f05dc69e5c6ebcd3131-X",
    tx_ref: Date.now(),
    amount: amount,
    currency: "USD",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email,
      phone_number,
      name,
    },
    customizations: {
      title: "EtaKenya",
      description: `Payment for Eta (${amount} USD)`,
      logo: "https://res.cloudinary.com/ddrlnh4tw/image/upload/v1720341533/yufc5fdsa9xc3xwzpyav.png",
    },
  };

  const fwConfig = {
    ...config,
    text: "Pay",
    callback: (response) => {
      console.log(response);
      onSuccess();
      closePaymentModal();
    },
    onClose: () => {
      showToast("Payment failed, please check your balance or retry", true);
    },
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 p-4">
      <h1 className="text-normal font-semibold text-center mb-4">
        You are about to pay USD {amount}
      </h1>
      <div className="flex justify-center">
        <FlutterWaveButton
          {...fwConfig}
          className="bg-green-500 text-white py-2 px-4 rounded shadow-md hover:bg-green-600 transition duration-200 ease-in-out"
        />
      </div>
    </div>
  );
};

export default Payment;
