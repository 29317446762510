import NewXIcon from "./NewXIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import logo from "../assets/logo.png";
import { PiTiktokLogoLight } from "react-icons/pi";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
const emailAddress = "info@etakenya.net";
const tiktokAddress = "https://www.tiktok.com/@etakenya";
const instagramAddress = "https://www.instagram.com/etakenya58/?hl=en";
const facebookAddress =
  "https://www.facebook.com/profile.php?id=61560223644364";
const footerProductLinks = [
  {
    name: "About us",
    link: "/about",
  },
  {
    name: "Terms of service",
    link: "/terms",
  },
  {
    name: "Privacy Policies",
    link: "/policies",
  },
  {
    name: "Refund Policy",
    link: "/refund-policy",
  },
];

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const Footer = () => {
  return (
    <div className="bg-black text-white">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:px-8 px-5 py-16 sm:text-center">
        <div className="text-center sm:text-start flex flex-col items-center sm:items-start">
          <img src={logo} alt="Logo" className="rounded-md h-24 w-24 mb-4" />
        </div>
        <ul className="text-center sm:text-start">
          {footerProductLinks.map((link, index) => (
            <li key={index}>
              <a
                href={link.link}
                className="text-gray-400 hover:text-teal-400 duration-300 text-sm leading-6 block"
              >
                {link.name}
              </a>
            </li>
          ))}
        </ul>
        <div className="text-center sm:text-start">
          <p>Follow us!</p>
          <div className="flex items-center justify-center sm:justify-start mt-4">
            <a
              href="https://x.com/EtaKenya9677"
              className="mr-4 hover:scale-105 duration-300"
            >
              <NewXIcon size={25} style={{ cursor: "pointer" }} />
            </a>
            <a href={facebookAddress}>
              <AiFillFacebook size={25} className="cursor-pointer" />
            </a>
            <a href={tiktokAddress}>
              <PiTiktokLogoLight
                size={25}
                style={{ marginLeft: "15px", cursor: "pointer" }}
              />
            </a>
            <a href={instagramAddress}>
              <AiFillInstagram
                size={25}
                style={{ marginLeft: "15px", cursor: "pointer" }}
              />
            </a>
          </div>
        </div>
        <div className="text-center sm:text-start">
          <p>Email Us!</p>
          <div className="flex items-center justify-center sm:justify-start mt-4">
            <a href={`mailto:${emailAddress}`}>
              <FontAwesomeIcon
                icon={faEnvelope}
                className="h-6 w-6 text-white hover:scale-105 duration-300"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="text-center pt-4 pb-8 text-gray-400 text-sm">
        <span>© {currentYear} Etakenya.net. All rights reserved.</span>
      </div>
    </div>
  );
};

export default Footer;
