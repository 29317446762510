import Footer from "./Footer";
const Terms = () => {
  return (
    <>
      <div className="container mx-auto px-4 py-8 font-poppins">
        <h1 className="text-3xl font-bold mb-4">Terms of Service</h1>
        <p className="mb-6">
          Welcome to EtaKenya! These Terms of Service ("Terms") govern your
          access to and use of the EtaKenya website, services, and any content
          or materials provided therein (collectively referred to as the
          "Services"). Please read these Terms carefully before using our
          Services.
        </p>

        <h2 className="text-xl font-bold mb-2">Acceptance of Terms</h2>
        <p className="mb-6">
          By accessing or using the Services, you agree to be bound by these
          Terms. If you do not agree with any provision of these Terms, you may
          not use the Services.
        </p>

        <h2 className="text-xl font-bold mb-2">Description of Services</h2>
        <p className="mb-6">
          EtaKenya provides immigration-related information, resources, and
          assistance through its website. The Services are intended to help
          individuals understand and navigate the immigration process, but they
          do not constitute legal advice or representation. Any reliance on the
          information provided is at your own risk.
        </p>

        <h2 className="text-xl font-bold mb-2">User Account</h2>
        <p>
          To access certain features of the Services, you may need to create a
          user account. You are responsible for maintaining the confidentiality
          of your account credentials and are solely responsible for any
          activity that occurs under your account. You agree to provide accurate
          and complete information when creating your account and to update it
          promptly if any changes occur.
        </p>
        <h2 className="text-xl font-bold mb-2">User Responsibilities</h2>
        <p>
          When using the Services, you agree to comply with all applicable laws
          and regulations. You will not use the Services for any unlawful or
          unauthorized purposes. You are responsible for any content you submit,
          and you represent and warrant that you have all necessary rights to
          submit such content.
        </p>
        <h2 className="text-xl font-bold mb-2">Intellectual Property Rights</h2>
        <p>
          The content and materials provided through the Services, including
          text, graphics, images, logos, and software, are owned or licensed by
          EtaKenya and are protected by intellectual property laws. You may not
          use, reproduce, modify, or distribute any of the content without prior
          written permission from EtaKenya.
        </p>
        <h2 className="text-xl font-bold mb-2">Third-Party Links</h2>
        <p>
          The Services may contain links to third-party websites or resources.
          EtaKenya is not responsible for the availability or accuracy of such
          external sites or resources. You acknowledge and agree that EtaKenya
          is not liable for any damages or losses incurred as a result of
          accessing or using any third-party websites or resources.
        </p>
        <h2 className="text-xl font-bold mb-2">Disclaimer of Warranties</h2>
        <p>
          The Services are provided on an "as is" and "as available" basis
          without any warranties of any kind, whether express or implied.
          EtaKenya disclaims all warranties, including but not limited to the
          implied warranties of merchantability, fitness for a particular
          purpose, and non-infringement.
        </p>
        <h2 className="text-xl font-bold mb-2">Limitation of Liability</h2>
        <p>
          To the maximum extent permitted by law, EtaKenya shall not be liable
          for any direct, indirect, incidental, consequential, or special
          damages arising out of or in connection with the use of the Services,
          even if advised of the possibility of such damages.
        </p>
        <h2 className="text-xl font-bold mb-2">Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless EtaKenya, its
          affiliates, officers, directors, employees, and agents from any
          claims, liabilities, damages, losses, or expenses (including
          reasonable attorneys' fees) arising out of or in connection with your
          use of the Services or any violation of these Terms.
        </p>
        <h2 className="text-xl font-bold mb-2">Modification and Termination</h2>
        <p>
          EtaKenya reserves the right to modify, suspend, or terminate the
          Services at any time without prior notice. We may also modify these
          Terms from time to time, and the updated Terms will be effective upon
          posting. Continued use of the Services after any modifications
          constitutes your acceptance of the updated Terms.
        </p>
        <h2 className="text-xl font-bold mb-2">
          Governing Law and Jurisdiction
        </h2>
        <p className="mb-6">
          These Terms shall be governed by and construed in accordance with the
          laws of Kenya. Any legal action or proceeding arising out of or in
          connection with these Terms shall be exclusively brought in the courts
          of Kenya.
        </p>
        <h2 className="text-xl font-bold mb-2">Entire Agreement</h2>
        <p>
          These Terms constitute the entire agreement between you and EtaKenya
          regarding the Services and supersede all prior agreements and
          understandings, whether oral or written."
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
