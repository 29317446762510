import {configureStore} from "@reduxjs/toolkit";
import authReducer from "../redux/features/auth/authSlice"
import visaReducer from "../redux/features/Visa/visaSlice"

import adminReducer from "./features/authSliceAdmin";

export const store = configureStore({
    reducer:{
      auth:authReducer,
      admin:adminReducer,
      visa:visaReducer
    }
})