// src/components/NewXIcon.js
import React from "react";
import { ReactComponent as NewXIconSVG } from "../assets/ReactComponent.svg";

const NewXIcon = ({ width = 24, height = 24, color = "black" }) => {
  return (
    <NewXIconSVG
      width={width}
      height={height}
      fill={color}
      className="ml-[15px] cursor-pointer"
    />
  );
};

export default NewXIcon;
