import React from "react";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8 font-poppins">
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold text-center mb-6">
          Privacy Policy of EtaKenya
        </h1>

        <p className="text-lg">
          At EtaKenya, we are committed to protecting the privacy and security
          of our clients' personal information. This Privacy Policy outlines how
          we collect, use, disclose, and safeguard your personal data when you
          use our website or engage with our immigration consultancy services.
          We encourage you to read this policy to understand how your personal
          information will be handled.
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">Information We Collect:</h2>
        <p className="text-lg">
          We may collect various types of personal information from you,
          including but not limited to your name, contact details, immigration
          history, and other relevant details necessary for providing our
          services. We may collect this information through our website,
          consultation sessions, forms, or other means.
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">
          Use of Personal Information:
        </h2>
        <p className="text-lg">
          We use the personal information we collect to provide immigration
          consultancy services tailored to your needs. This includes assisting
          with visa applications, preparing documentation, conducting
          interviews, and providing guidance throughout the immigration process.
          We may also use your information to communicate with you, improve our
          services, and comply with legal obligations.
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">
          Disclosure of Personal Information:
        </h2>
        <p className="text-lg">
          We may disclose your personal information to third parties, including
          government authorities, immigration officials, and service providers
          who assist us in delivering our services. These third parties are
          obligated to protect your information and use it only for the purposes
          for which it was disclosed.
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">Data Security:</h2>
        <p className="text-lg">
          We take reasonable precautions to protect your personal information
          from unauthorized access, disclosure, alteration, or destruction. We
          have implemented physical, technical, and administrative safeguards to
          secure the information we collect.
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">
          Cookies and Tracking Technologies:
        </h2>
        <p className="text-lg">
          Our website may use cookies and similar tracking technologies to
          enhance your browsing experience and collect information about how you
          interact with our site. These technologies may collect information
          such as your IP address, browser type, pages visited, and other usage
          details. You can adjust your browser settings to refuse cookies, but
          please note that some features of our website may not function
          properly without them.
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">Third-Party Websites:</h2>
        <p className="text-lg">
          Our website may contain links to third-party websites or services that
          are not under our control. We are not responsible for the privacy
          practices or content of these third-party sites. We encourage you to
          review their privacy policies before providing any personal
          information.
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">
          Retention of Personal Information:
        </h2>
        <p className="text-lg">
          We retain your personal information for as long as necessary to
          fulfill the purposes for which it was collected and to comply with
          legal obligations. When no longer required, we will securely delete or
          anonymize your information.
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">Your Rights:</h2>
        <p className="text-lg">
          You have certain rights regarding your personal information, including
          the right to access, correct, and delete your data. If you wish to
          exercise these rights or have any questions about our privacy
          practices, please contact us using the information provided below.
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">
          Changes to the Privacy Policy:
        </h2>
        <p className="text-lg">
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. We encourage you to review the
          policy periodically for any updates.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
