import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const avatar = require("../assets/EtaAvatar.jpg");
const biodataPage = require("../assets/EtaBiodata.png");

function ApplicationGuide() {
  const showToast = (message, isError = false) => {
    toast(message, {
      duration: 10000,
      style: {
        background: isError ? "#FF6F61" : "#4BB543",
        color: "white",
      },
      position: "top-center",
    });
  };

  const [terms, setTerms] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!terms) {
      showToast(
        "Please accept the terms and conditions to continue with your application",
        true
      );
      return;
    }
    showToast("You are now redirected to the application page", false);
    navigate("/eta-application");
  };

  const highRiskCountries = [
    "Somalia",
    "Libya",
    "Kosovo",
    "North Korea",
    "Syria",
    "Tajikistan",
    "Palestine",
    "Lebanon",
    "Yemen",
    "Afghanistan",
    "Iraq",
    "Armenia",
    "Azerbaijan",
  ];

  return (
    <div className="min-h-screen flex justify-center items-center p-4">
      <Toaster />
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-2xl">
        <form onSubmit={handleSubmit}>
          <h1 className="text-2xl font-bold text-green-700 mb-6">
            How to Apply Your ETA
          </h1>
          <hr className="border border-emerald-500" />

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-green-600 mb-2">
              Country Payment Information
            </h2>
            <p className="text-green-800 mb-4">
              Please note the payment amounts based on your country:
            </p>
            <ul className="list-disc list-inside mb-4 text-green-800">
              {highRiskCountries.map((country, index) => (
                <li key={index} className="text-red-500">
                  {country} - $151
                </li>
              ))}
              <li className="text-green-500">All other countries - $100</li>
            </ul>
          </div>

          <hr className="m-4" />

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-green-600 mb-2">
              How to upload your Passport Image
            </h2>
            <ul className="list-disc list-inside mb-4 text-green-800">
              <li>The image should be clear and well-lit.</li>
              <li>Ensure the background is plain and the face is centered.</li>
              <li>No shadows or glares on the face.</li>
              <li>The image should be in color.</li>
              <li>
                The images format must be in{" "}
                <span className="text-red-500">(png, jpeg, jpg)</span> and
                should not exceed 500KB.
              </li>
            </ul>
            <img src={avatar} alt="ImageGuidelines" />
          </div>

          <hr className="m-4" />

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-green-600 mb-2">
              Sample of Passport Biodata Page
            </h2>
            <p className="text-green-800 mb-4">
              Ensure the image includes all details, including{" "}
              <span>
                <a
                  className="text-red-500"
                  href="https://static-content.regulaforensics.com/Blog/0248%20-%202.webp"
                >
                  the Machine-readable zone (MRZ).
                </a>
              </span>
            </p>
            <img
              src={biodataPage}
              alt="Passport Biodata Page"
              className="w-full shadow-md mb-6"
            />
          </div>

          <hr className="m-4" />

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-green-600 mb-2">
              Mode of Payment
            </h2>
            <p className="text-green-800 mb-4">We accept all card payments.</p>
          </div>

          <hr className="m-4" />

          <div className="mb-6">
            <label className="inline-flex items-center text-green-800">
              <input
                type="checkbox"
                name="terms"
                checked={terms}
                onChange={(e) => setTerms(e.target.checked)}
                className="form-checkbox text-green-600"
              />
              <span className="ml-2">
                I accept the terms and conditions.{" "}
                <a className="text-red-500" href="https://etakenya.net/terms">
                  Click here
                </a>{" "}
              </span>
            </label>
          </div>

          <div className="flex justify-end">
            <button className="bg-green-600 text-white py-2 px-4 rounded shadow hover:bg-green-700 transition duration-200">
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ApplicationGuide;
