

import { useNavigate } from "react-router-dom";
const NotFound = () => {
    const navigate = useNavigate()
    const gotoHome = async () => {
        navigate("/")
    }
  return (
    <div className="flex items-center flex-col mt-4 mx-4">
    <h1 className=" text-red-500 text-2xl p-4">ERROR:404!!</h1>  
    <h2>Page Not Found!! 🥺</h2>
      <h3>The requested URL could not be found.</h3>
      <p>If you have missed something please visit our home page to start a fresh. 🚀🚀</p>
      <p>Click here 👇👇</p>
      <button onClick={gotoHome} className=" p-2 bg-green-500 mt-4 hover:bg-green-600 w-[200px]">Home</button>
    </div>
  )
}

export default NotFound