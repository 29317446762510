import { createSlice } from "@reduxjs/toolkit";
//const adminUsername = JSON.parse(localStorage.getItem("adminUsername"));
const initialState = {
  isAdminLoggedin: false,
 // adminUsername: adminUsername ? adminUsername : "",
  admin: {
    adminUserId: "",
    adminUsername: "",
  },
};

const authSliceAdmin = createSlice({
  name: "admin",
  initialState,
  reducers: {
    SET_ADMIN_LOGIN(state, action) {
      state.isAdminLoggedin = action.payload;
    },
    SET_ADMIN_USERNAME(state, action) {
      localStorage.setItem("adminUsername", JSON.stringify(action.payload));
      state.isAdminLoggedin = action.payload;
    },
    SET_ADMIN(state, action) {
      const profile = action.payload;
      state.admin.adminUsername = profile.adminUsername;
      state.admin.adminUserId = profile.adminUserId;
    },
  },
});

export const { SET_ADMIN_LOGIN, SET_ADMIN, SET_ADMIN_USERNAME } =
  authSliceAdmin.actions;
export const selectIsAdminLoggedin = (state)=>state.adminAuth.isAdminLoggedin;
export const selectAdmin = (state)=>state.adminAuth.admin;
export default authSliceAdmin.reducer;  
