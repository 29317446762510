import { useEffect } from "react";





const TawkToChat = () => {
  useEffect(() => {
    var _Tawk_API = _Tawk_API || {};
    var _Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script");
      var s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/66bc77d8146b7af4a43a33d3/1i5838a24";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  return null;
};

export default TawkToChat;
