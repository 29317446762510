import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../server";

export const adminLogin = async (userData) => {
  try {
    const response = axios.post(
      `${server}/users/admin/kenvisas/login`,
      userData
    );
    if ((await response).statusText === "OK") {
      toast.success(`Welcome back ${response.adminUsername}`);
    }
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};
//Admin Logout
export const logoutAdmin = async () => {
  try {
    await axios.get(`${server}/users/admin/kenvisas/logout`);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};
