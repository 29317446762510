import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import { server } from "../server";

const FinalSubmit = ({ formData }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const showToast = (message, isError = false) => {
    toast(message, {
      duration: 10000,
      style: {
        background: isError ? "#FF6F61" : "#4BB543",
        color: "white",
      },
      position: "top-center",
    });
  };

  const validateForm = () => {
    const { email, telephone, passportPhoto, bioDataPage } = formData;
    if (!email || !telephone || !passportPhoto || !bioDataPage) {
      showToast("Please fill in all required fields", true);
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      showToast("Please enter a valid email address", true);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (key === "passportPhoto" || key === "bioDataPage") {
          if (formData[key]) {
            formDataToSend.append(key, formData[key]);
          }
        } else {
          formDataToSend.append(key, formData[key]);
        }
      }
      setIsLoading(true);
      await axios.post(`${server}/visas/create-eta`, formDataToSend);

      showToast("Your ETA data is sent successfully", false);
      showToast("You are now redirected to the homepage 🚀🚀", false);
      sessionStorage.removeItem("formData");
      navigate("/");
      setIsLoading(false);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      showToast(errorMessage, true);
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4 text-center p-4 bg-green-500">
        Submit Your Data
      </h2>
      <form onSubmit={handleSubmit}>
        <button
          type="submit"
          className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
        >
          Submit Application
        </button>
      </form>
      {isLoading && <Loader />}
    </div>
  );
};

export default FinalSubmit;
