import { useState } from "react";
import { FaInfoCircle, FaChevronUp, FaChevronDown } from "react-icons/fa";
import Footer from "./Footer";

const descriptions = {
  "Simplifying the Eta Process:":
    "EtaKenya streamlines the process of obtaining an Eta, ensuring a hassle-free experience for travelers. We guide you through every step, from filling out the application to receiving your visa, making sure you have all the necessary documentation and information. Our efficient process helps you save time and avoid common pitfalls, allowing you to focus on your travel plans.",

  "Expert Guidance and Support:":
    "Our team of experts is dedicated to providing you with the best guidance and support throughout your Eta application process. We offer personalized assistance, answering your questions and helping you navigate any complexities. Whether you're applying for the first time or need assistance with a specific issue, we're here to ensure your experience is smooth and successful.",

  "Quick Turnaround Time:":
    "We understand the importance of timely service, especially when it comes to travel plans. EtaKenya is committed to processing your Eta application as quickly as possible. Our streamlined procedures and experienced staff work together to minimize delays, ensuring you receive your visa in a timely manner so you can travel with peace of mind.",

  "Secure and Confidential:":
    "Your privacy and security are our top priorities. At EtaKenya, we use advanced security measures to protect your personal information throughout the Eta application process. We handle all your data with the utmost care, ensuring that your information remains confidential and secure at all times.",
};

const LearnMore = () => {
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  return (
    <>
      <section className="py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8">
            <FaInfoCircle className="text-green-500 md:text-4xl text-2xl mb-2 mx-auto" />
            <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 ">
              Learn More About EtaKenya
            </h2>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto">
              Discover how EtaKenya can assist you in obtaining your Eta quickly
              and efficiently. Click on each topic to learn more.
            </p>
          </div>

          <ul className="space-y-4">
            {Object.keys(descriptions).map((item, index) => (
              <li
                key={index}
                className="relative group p-4 border rounded cursor-pointer hover:bg-green-500 duration-300"
                onClick={() => toggleItem(index)}
              >
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-medium text-gray-900 group-hover:text-white">
                    {item}
                  </h3>
                  {openItem === index ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                {openItem === index && (
                  <div className="mt-2 p-2 bg-gray-100 rounded transition-opacity duration-300 ease-in-out">
                    <p className="text-gray-700">{descriptions[item]}</p>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default LearnMore;
