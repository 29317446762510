import axios from "axios";
import { toast } from "react-hot-toast";
import { server } from "../server";

const showToast = (message, isError = false) => {
  toast(message, {
    duration: 5000,
    style: {
      background: isError ? "#FF6F61" : "#4BB543",
      color: "white",
    },
    position: "top-center",
  });
};
//validate email
export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

//Register User
export const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${server}/users/register`, userData, {
      withCredentials: true,
    });
    if (response.statusText === "OK") {
      showToast(`Welcome to Kenvisas, ${response.data.username}`, false);
    }
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    showToast(message, true);
  }
};

// Login User
export const loginUser = async (userData) => {
  try {
    const response = await axios.post(`${server}/users/login`, userData);
    if (response.statusText === "OK") {
      showToast(`Welcome back,  ${response.data.username} 🚀🚀😍`, false);
    }
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    showToast(message, true);
  }
};

// Get Login Status
export const getLoginStatus = async () => {
  try {
    const response = await axios.get(`${server}/users/loggedin`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    showToast(message, true);
  }
};
// Logout User
export const logoutUser = async () => {
  try {
    await axios.get(`${server}/users/logout`);
    showToast("You have logged out succesfully", false);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    showToast(message, true);
  }
};
// Forgot password
export const forgotPassword = async (userData) => {
  try {
    const response = await axios.post(
      `${server}/users/forgotpassword`,
      userData
    );
    toast.success(response.data.message);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    showToast(message, true);
  }
};

// Reset Password

export const resetPassword = async (userData, resetToken) => {
  try {
    const response = await axios.put(
      `${server}/users/resetpassword/${resetToken}`,
      userData
    );
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};
// Get User Data
export const getUserData = async () => {
  try {
    const response = await axios.get(`${server}/api/visas`);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    showToast(message, true);
  }
};
