import { useState } from "react";
import logo from "../assets/logo.png";
import { RiMenuLine, RiCloseLine } from "react-icons/ri";
import { ShowOnLogin, ShowOnLogout } from "../protect/HiddenLinks";
import { SET_LOGIN } from "../redux/features/auth/authSlice";
import { logoutUser } from "../services/authService";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = async (e) => {
    e.preventDefault();
    await logoutUser();
    await dispatch(SET_LOGIN(false));
    navigate("/");
  };

  return (
    <>
      <nav className="bg-white  z-[999px] pb-2">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center h-20">
            <a href="/">
              <img
                className="md:h-[250px] h-40 cursor-pointer  transition-transform duration-300 hover:overflow-hidden"
                src={logo}
                alt="Logo"
              />
            </a>
            {/* Visible on medium screens and above */}
            <div className="hidden md:flex items-center space-x-4">
              <a
                href="/"
                className="px-3 py-2 text-sm font-medium text-gray-900 hover:scale-105 transition-transform duration-300"
              >
                Home
              </a>
              <ShowOnLogin>
                <a
                  href="/application-guide"
                  className="px-3 py-2 text-sm font-medium text-gray-900 hover:scale-105 transition-transform duration-300"
                >
                  ETA
                </a>
              </ShowOnLogin>
              <a
                href="/about"
                className="px-3 py-2 text-sm font-medium text-gray-900 hover:scale-105 transition-transform duration-300"
              >
                About Us
              </a>
              <ShowOnLogout>
                <a
                  href="/sign-up"
                  className="px-4 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg hover:bg-gray-700 transition-colors duration-300"
                >
                  Sign Up
                </a>
              </ShowOnLogout>
              <ShowOnLogout>
                <a
                  href="/login"
                  className="px-4 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg hover:bg-gray-700 transition-colors duration-300"
                >
                  Log In
                </a>
              </ShowOnLogout>
              <ShowOnLogin>
                <button
                  onClick={logout}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-700 transition-colors duration-300"
                >
                  Log Out
                </button>
              </ShowOnLogin>
            </div>

            {/* Menu Button */}
            <button
              onClick={toggleMenu}
              className="md:hidden p-2 text-gray-900 hover:text-white hover:bg-gray-900 rounded-md transition-colors duration-300"
            >
              {isOpen ? (
                <RiCloseLine className="h-6 w-6" />
              ) : (
                <RiMenuLine className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
        {/* Mobile Menu */}
        {isOpen && (
          <div className="md:hidden z-10 ">
            <div className="px-4 py-2 space-y-2">
              <a
                href="/"
                className="block py-2 text-base font-medium text-black hover:bg-gray-200 rounded-md transition-colors duration-300"
              >
                Home
              </a>
              <ShowOnLogin>
                <a
                  href="/application-guide"
                  className="block py-2 text-base font-medium text-black hover:bg-gray-200 rounded-md transition-colors duration-300"
                >
                  ETA
                </a>
              </ShowOnLogin>
              <a
                href="/about"
                className="block py-2 text-base font-medium text-black hover:bg-gray-200 rounded-md transition-colors duration-300"
              >
                About Us
              </a>
              <ShowOnLogout>
                <a
                  href="/sign-up"
                  className="block py-2 text-center text-white bg-black hover:bg-gray-700 rounded-md transition-colors duration-300"
                >
                  Sign Up
                </a>
              </ShowOnLogout>
              <ShowOnLogout>
                <a
                  href="/login"
                  className="block py-2 text-center text-white bg-black hover:bg-gray-700 rounded-md transition-colors duration-300"
                >
                  Log In
                </a>
              </ShowOnLogout>
              <ShowOnLogin>
                <Link
                  onClick={logout}
                  className="block py-2 text-center text-white bg-red-500 hover:bg-red-700 rounded-md transition-colors duration-300"
                >
                  Log Out
                </Link>
              </ShowOnLogin>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default NavBar;
