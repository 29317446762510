import React from "react";
import Footer from "./Footer";

const RefundPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8 font-poppins">
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold text-center mb-6">
          Refund and cancellation policy
        </h1>

        <p className="text-lg">
          EtaKenya is committed to providing excellent visa services to our
          customers. We understand that circumstances may arise where you might
          need to cancel your order and request a refund. Our refund policy is
          designed to ensure fairness and transparency for both parties
          involved.
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">Cancellation process:</h2>
        <p className="text-lg">
          Contact our customer support team at{" "}
          <a className=" text-green-500" href="mailto:info@EtaKenya.com">
            Email us
          </a>{" "}
          or chat us through the floating whatsapp icon and provide your order
          details. Our team will guide you through the cancellation process and
          provide any necessary information. Cancellation requests can only be
          processed during our business hours <strong>[2hours]</strong> . Once a
          cancellation request is received, we will initiate the refund process.
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">Refund Processing:</h2>
        <p className="text-lg">
          Refunds will be processed within 2 business days of receiving a valid
          cancellation request. The refund will be issued using the same payment
          method that was used for the original transaction, unless otherwise
          specified. Depending on your payment provider, it may take additional
          time for the refunded amount to appear in your account.
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">Cancellation Fees:</h2>
        <p className="text-lg">
          If the cancellation request is made before the visa application
          submission, a full refund will be provided, and no cancellation fee
          will be charged. If the cancellation request is made after the visa
          application has been submitted but before any processing has occurred,
          a cancellation fee of [fee amount or percentage] will be deducted from
          the refund. If the cancellation request is made after the visa
          processing has begun, the cancellation fee will be{" "}
          <strong>[10%]</strong> of the total amount, and the remaining amount
          will be refunded.
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">Exceptional Cases:</h2>
        <p className="text-lg">
          In exceptional circumstances, such as instances where the visa
          application has been rejected by the relevant authorities, we will
          provide a full refund, including any applicable cancellation fees.
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">Contact us:</h2>
        <p className="text-lg mb-4">
          If you have any questions or concerns regarding our refund and
          cancellation policy, please feel free to reach out to our customer
          support team at{" "}
          <a className=" text-green-500" href="mailto:info@EtaKenya.com">
            Email
          </a>
          . We are here to assist you and provide clarification on any matters
          related to our services and policies. Please note that this policy is
          subject to change without prior notice. It is recommended to review
          this policy periodically for any updates. By using our services, you
          acknowledge that you have read, understood, and agreed to the terms
          and conditions outlined in this Refund and Cancellation Policy.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
