import { toast } from "react-hot-toast";
import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../Loader";
import styles from "../../style";
import { server } from "../../server";

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const showToast = (message, isError = false) => {
    toast(message, {
      duration: 5000,
      style: {
        background: isError ? "#FF6F61" : "#4BB543",
        color: "white",
      },
      position: "top-center",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${server}/users/login`,
        { email, password },
        { withCredentials: true }
      );

      const { username } = response.data;
      setIsLoggedIn(true);
      showToast(
        `Welcome back ${username}🚀🚀. Apply or continue with your application`,
        false
      );
      navigate("/application-guide");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      showToast(errorMessage, true);
    }
  };

  return (
    <div className="min-h-screen  flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Login to your account
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-coralPink focus:border-pink sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-coralPink focus:border-pink sm:text-sm"
                />
                <div
                  className="absolute right-2 top-2 cursor-pointer"
                  onClick={() => setVisible(!visible)}
                >
                  {visible ? (
                    <AiOutlineEye size={25} />
                  ) : (
                    <AiOutlineEyeInvisible size={25} />
                  )}
                </div>
              </div>
            </div>

            <div className={`${styles.noramlFlex} justify-between`}>
              <div className={`${styles.noramlFlex}`}>
                <input
                  id="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-pink focus:ring-coralPink border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
              {isLoading ? (
                <Loader />
              ) : (
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-300 hover:bg-green-500 duration-300"
                >
                  Login
                </button>
              )}
            </div>

            {/* Sign Up Link */}
            <div className={`${styles.noramlFlex} `}>
              <div className="flex justify-between">
                <h4>Not have any account?</h4>
                <Link
                  to="/sign-up"
                  className="text-green-500 hover:green-600 pl-2"
                >
                  Sign Up
                </Link>
              </div>
            </div>
            <div className="text-sm">
              <Link to="/forgot-password" className="font-medium text-red-500">
                Forgot your password?
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
